<template>
  <div>
    <div class="card p-lg-10 p-sm-5" style="height: 80vh; overflow-y: auto">
      <div class="h-100">
        <div style="height: calc(100% - 60px); overflow-y: auto">
          <div>
            <h2 class="text-blue fw-bolder m-0">
              SENSOR <span>{{ $route.params.id }}</span>
            </h2>
            <span class="text-blue">Bodega2/cuardo frio 1</span>
          </div>
          <div>
            <div class="d-flex my-5">
              <div
                class="bg-blue rounded-circle d-flex me-5 align-items-center justify-content-center"
                style="width: 68px !important; height: 68px !important"
              >
                <img
                  alt=""
                  class="img-fluid"
                  src="/media/svg/files/document_bitacora.svg"
                />
              </div>
              <div style="width: calc(70% - 100px) !important">
                <div class="card p-5 bg-blue">
                  <h2
                    class="text-white"
                    v-if="infoBitacora.justification == null"
                  >
                    Pendiente respuesta
                  </h2>
                  <h2 class="text-white" v-if="infoBitacora.justification">
                    Falla resuelta
                  </h2>
                  <p class="text-white fs-4">{{ infoBitacora.message }}</p>
                </div>
                <span class="mx-5" style="color: #707070">{{
                  fecha(infoBitacora.createdAt)
                }}</span>
              </div>
            </div>
            <div
              class="d-flex my-5 justify-content-end"
              v-if="infoBitacora.justification"
            >
              <div style="width: calc(70% - 100px) !important">
                <div
                  class="card p-5 me-5 shadow-none"
                  style="background-color: #f4f8fb"
                >
                  <h2 style="color: #6ace53">Falla resuelta</h2>
                  <p class="text-dark fs-4">{{ infoBitacora.justification }}</p>
                </div>
                <span class="mx-5" style="color: #707070">{{
                  fecha(infoBitacora.updatedAt)
                }}</span>
              </div>
              <div
                class="rounded-circle d-flex align-items-center justify-content-center"
                style="
                  width: 68px !important;
                  height: 68px !important;
                  background-color: #f4f8fb;
                "
              >
                <img
                  alt=""
                  class="img-fluid"
                  src="/media/svg/files/document_bitacora.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            class="form-control p-2 justify-content-between d-flex"
            style="box-shadow: 0.17px 5px 19px #7b7b7b38 !important"
            v-if="infoBitacora.justification == null"
          >
            <input
              type="text"
              class="border-0 form-control p-1"
              style="width: 93%"
              v-model="respuesta"
              placeholder="Escriba aqui la respuesta"
            />
            <button
              class="btn rounded-circle bg-blue p-2"
              style="width: 45px; height: 45px"
              @click="responderBitacora"
            >
              <i class="bi bi-send text-white fs-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import usuario from "@/services/bitacora.service";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import es from "dayjs/locale/es";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "chat",
  components: {},
  setup() {
    const infoBitacora = ref({});
    const route = useRoute();
    // console.log("id", route.params.id);
    const traerInformacionDeLaBitacora = () => {
      usuario.traerInfoBitacora(route.params.id).then((res) => {
        // console.log("bitacora", res.data);
        infoBitacora.value = res.data;
      });
    };
    const respuesta = ref("");
    const responderBitacora = () => {
      usuario
        .editarBitacora(route.params.id, { justification: respuesta.value })
        .then(() => {
          // console.log(res);
          traerInformacionDeLaBitacora();
          respuesta.value = "";
        });
    };
    const fecha = (valor) => {
      dayjs.extend(utc);
      dayjs.extend(timezone);
      dayjs.locale(es);
      return dayjs.tz(valor, "America/Bogota").format("DD/MM/YYYY HH:mm");
    };
    onMounted(() => {
      traerInformacionDeLaBitacora();
      setCurrentPageBreadcrumbs("Campaigns", ["Pages", "Profile"]);
    });
    return { infoBitacora, fecha, responderBitacora, respuesta };
  },
});
</script>
